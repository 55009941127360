import Header from "../components/Header";
import { useNavigate, useParams, useResolvedPath } from "react-router";
import { useState, useEffect } from "react";
import $ from "jquery";
import ReactModal from "react-modal";
import ReactPlayer from "react-player";
import { GrEdit } from "react-icons/gr";
import { ImCheckmark, ImCopy, ImCross } from "react-icons/im";
import { GiPreviousButton, GiNextButton } from "react-icons/gi";
import { GrRotateLeft, GrRotateRight } from "react-icons/gr";
import { confirm } from "../components/Confirmation";
import { cloneDeep } from "lodash";
import MakeZIPComponent from "../components/MakeZIPComponent";
import "../index.css";
import toast, { Toaster } from "react-hot-toast";
import { useCopyToClipboard } from "usehooks-ts";
import ShareWithModal from "../components/ShareWithModal";
import { Delete, Refresh, RestoreFromTrash } from "@mui/icons-material";
import EventOptions from "../components/EventOptions";

const URL = process.env.REACT_APP_SITE;
const token = localStorage.getItem("token");

function openMedia(index, setState, setMediaID) {
  setState(true);
  setMediaID(index);
}

function handleKeyDown(e, mediaID, setMediaID, maxMediaID) {
  if (e.key === "ArrowLeft" && mediaID !== 0) {
    setMediaID((mediaID = mediaID - 1));
  } else if (e.key === "ArrowRight" && mediaID !== maxMediaID - 1) {
    setMediaID((mediaID = mediaID + 1));
  }
}

async function handleReset(
  setEditMode,
  setEvent,
  eventID,
  users,
  setSharedWith,
  setAllUsers
) {
  if (await confirm("Visszavonod a változtatásokat?")) {
    setEditMode(false);
    downloadData(setEvent, eventID, users, setSharedWith, setAllUsers);
  }
}

async function handleConfirm(setEditMode, event) {
  if (await confirm("Elmented a változtatásokat?")) {
    setEditMode(false);
    $.ajax({
      type: "POST",
      url: URL + "editEvent",
      headers: { token: localStorage.getItem("token") },
      data: { eventID: event.EventID, plateName: event.LicensePlate },
      success: function (data) {},
    });
  }
}

async function handleSharedConfirm(setShareModalState, event, sharedWithArray) {
  setShareModalState(false);
  let swArray = [];
  sharedWithArray.forEach((element) => {
    swArray.push(element.UserID);
  });
  if (swArray.length === 0) {
    swArray.push("");
  }
  $.ajax({
    type: "POST",
    url: URL + "editEvent",
    headers: { token: localStorage.getItem("token") },
    data: { eventID: event.EventID, sharedWith: swArray },
    success: function (data) {},
  });
}

function downloadData(
  setEvent,
  eventID,
  setSharedWith,
  setAllUsers,
  showDeletedMedia,
  navigate = null
) {
  $.ajax({
    url:
      URL +
      `getEvent?eventId=${eventID}${showDeletedMedia ? `&deleted=true` : ""}`,
    headers: { token: localStorage.getItem("token") },
    success: function (data) {
      if (!data.Media && navigate) return navigate(-1);
      setEvent(data);
    },
    dataType: "json",
  });
  $.ajax({
    url: URL + "getSharedWith?eventID=" + eventID,
    headers: { token: localStorage.getItem("token") },
    success: function (data) {
      setSharedWith(data);
    },
    dataType: "json",
  });
  $.ajax({
    url: URL + "getAllMembers",
    success: function (data) {
      setAllUsers(
        data.filter((e) => e.UserID !== Number(localStorage.getItem("userID")))
      );
    },
    dataType: "json",
  });
}

function changeLicensePlate(event, setEvent, licensePlate) {
  const newEvent = cloneDeep(event);
  newEvent.LicensePlate = licensePlate;
  setEvent(newEvent);
}

function getNamesFrom(sharedWith) {
  if (sharedWith.length === 0) return "Nincs";
  return sharedWith.map((user) => user.FullName).join(", ");
}

const deleteMedia = (mediaId, onMediaChange) => {
  $.ajax({
    url: URL + `deleteMedia?mediaId=${mediaId}`,
    method: "PUT",
    headers: {
      token,
    },
    success: onMediaChange,
    error: function (error) {
      console.error(error);
      toast.error(
        error.status === 403
          ? "Fájlt csak tulajdonos törölhet!"
          : "Hiba történt"
      );
    },
  });
};

const restoreMedia = (mediaId, onMediaChange) => {
  $.ajax({
    url: URL + `restoreMedia?mediaId=${mediaId}`,
    method: "PUT",
    headers: {
      token,
    },
    success: onMediaChange,
    error: function (error) {
      console.error(error);
    },
  });
};

const rotateMedia = (direction, idMedia, onMediaChange) => {
  fetch(URL + "rotateMedia", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idMedia: idMedia,
      rotate: direction,
    }),
  })
    .then((response) => response.json())
    .then((res) => onMediaChange(res));
};

const refreshThumbnail = (mediaId, onMediaChange) => {
  $.ajax({
    url: URL + `generateThumbnail`,
    method: "POST",
    headers: {
      token,
    },
    data: { mediaId },
    success: (res) => onMediaChange(res),
    error: function (error) {
      console.error(error);
    },
  });
};

function Thumbnail(props) {
  const index = props.index;
  const media = props.media;
  const setModalState = props.setModalState;
  const setMediaID = props.setMediaID;
  const onMediaStateChanged = props.onMediaStateChanged;
  const onThumbnailRefresh = props.onThumbnailRefresh;
  const URL = process.env.REACT_APP_SITE;

  return (
    <div className="thumbnailContainer">
      <img
        key={index}
        onClick={() => {
          openMedia(index, setModalState, setMediaID);
        }}
        alt={media.idMedia}
        src={media.ThumbnailURL}
        onError={({ target }) =>
          (target.src =
            URL + "public/fileMissing.png")
        }
      />
      <div className="mediaOptions">
        <div
          title={media.StoreEnds ? "Visszaállítás" : "Törlés"}
          onClick={() =>
            media.StoreEnds
              ? restoreMedia(media.idMedia, onMediaStateChanged)
              : deleteMedia(media.idMedia, onMediaStateChanged)
          }
        >
          {media.StoreEnds ? (
            <RestoreFromTrash sx={{ color: "white" }} />
          ) : (
            <Delete sx={{ color: "white" }} />
          )}
        </div>
        <div
          title="Frissítés"
          onClick={() => refreshThumbnail(media.idMedia, onThumbnailRefresh)}
        >
          <Refresh sx={{ color: "white" }} />
        </div>
      </div>
    </div>
  );
}

function endDate(event) {
  if(event.StoreEnds || event.Media?.some(media => media.StoreEnds)) {
    const dates = [...(event.StoreEnds ? [new Date(event.StoreEnds)] : []), ...(event.Media?.some(media => media.StoreEnds) ? event.Media?.filter(media => media.StoreEnds).map(media => new Date(media.StoreEnds)) : [])];
    const minDate = new Date(Math.min(...dates));
    console.dir({event, dates, minDate});
    return "Elérhető eddig: " + minDate.toLocaleDateString();
  }
  if(event.ArchiveEnds) return " Archív eddig: " + new Date(event.ArchiveEnds).toLocaleDateString();
  return "";
}


function EventPage() {
  const params = useParams();
  const navigate = useNavigate();
  const pathName = useResolvedPath().pathname.split("/");
  const [value, copy] = useCopyToClipboard();
  const [event, setEvent] = useState({});
  const [sharedWith, setSharedWith] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [shareModalState, setShareModalState] = useState(false);
  const [mediaID, setMediaID] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const eventID = params.eventId;
  const showDeletedMedia = params.show === "showDeletedMedia";

  console.dir(event);

  useEffect(() => {
    downloadData(
      setEvent,
      eventID,
      setSharedWith,
      setAllUsers,
      showDeletedMedia
    );
  }, []);

  useEffect(() => {
    if (!value) return;
    toast.success("Vágólapra helyezve");
  }, [value]);

  return (
    <div
      onKeyDown={(e) =>
        handleKeyDown(
          e,
          mediaID,
          setMediaID,
          event.Media ? event.Media.length : 0
        )
      }
    >
      <Header />
      <Toaster position="top-center" />

      <ReactModal
        isOpen={modalState}
        ariaHideApp={false}
        onRequestClose={() => setModalState(false)}
      >
        <div
          style={{
            height: "98%",
            width: "100%",
            position: "relative",
          }}
        >
          <button
            style={{ float: "right" }}
            className="btn-close"
            aria-label="Bezárás"
            onClick={() => {
              setModalState(false);
            }}
          ></button>
          {/* <RiDeleteBinLine style={{ float: 'right', fontSize:23, marginRight: '10px', cursor: 'pointer', color: 'red' }} aria-label='Delete' onClick={() => { setModalState(false); }}/> */}
          <div
            style={{
              width: "100%",
              height: "90%",
              textAlign: "center",
              flex: 1,
              flexDirection: "row",
            }}
          >
            <div>
              <GrRotateLeft
                title="Forgatás balra"
                size={100}
                color={
                  event?.Media?.at(mediaID).IsVideo ? "gray" : "dodgerblue"
                }
                onClick={() => {
                  if (event?.Media?.at(mediaID).IsVideo) return;
                  rotateMedia(
                    "left",
                    event?.Media?.at(mediaID).idMedia,
                    (newUrls) =>
                      setEvent((event) => ({
                        ...event,
                        Media: event.Media.map((media, i) => ({
                          ...media,
                          ...(i === mediaID && newUrls),
                        })),
                      }))
                  );
                }}
                style={{
                  position: "absolute",
                  left: "2%",
                  top: "50%",
                  cursor: event?.Media?.at(mediaID).IsVideo
                    ? "default"
                    : "pointer",
                }}
              />

              <GiPreviousButton
                title="Előző"
                size={100}
                color={mediaID === 0 ? "gray" : "dodgerblue"}
                onClick={() => {
                  if (mediaID === 0) return;
                  setMediaID(mediaID - 1);
                }}
                style={{
                  position: "absolute",
                  left: "2%",
                  top: "35%",
                  cursor: mediaID === 0 ? "default" : "pointer",
                }}
              />
            </div>
            {(event.Media ? event.Media[mediaID].IsVideo : false) ? (
              <ReactPlayer
                url={event.Media ? event.Media[mediaID].MediaURL : ""}
                controls={true}
                width="80%"
                height="100%"
                style={{
                  position: "relative",
                  left: "10%",
                  top: "2%",
                }}
              />
            ) : (
              <img
                alt=""
                src={event.Media ? event.Media[mediaID].MediaURL : ""}
                draggable="true"
                style={{ maxHeight: "100%", maxWidth: "80%" }}
              />
            )}
            <div>
              <GiNextButton
                title="Következő"
                size={100}
                color={
                  mediaID === (event.Media?.length ?? 0) - 1
                    ? "gray"
                    : "dodgerblue"
                }
                onClick={() => {
                  if (mediaID === (event.Media.length ?? 0) - 1) return;
                  setMediaID(mediaID + 1);
                }}
                style={{
                  position: "absolute",
                  right: "2%",
                  top: "35%",
                  cursor:
                    mediaID === (event.Media?.length ?? 0) - 1
                      ? "default"
                      : "pointer",
                }}
              />
              <GrRotateRight
                title="Forgatás jobbra"
                size={100}
                color={
                  event?.Media?.at(mediaID).IsVideo ? "gray" : "dodgerblue"
                }
                onClick={() => {
                  if (event?.Media?.at(mediaID).IsVideo) return;
                  rotateMedia(
                    "right",
                    event?.Media?.at(mediaID).idMedia,
                    (newUrls) =>
                      setEvent((event) => ({
                        ...event,
                        Media: event.Media.map((media, i) => ({
                          ...media,
                          ...(i === mediaID && newUrls),
                        })),
                      }))
                  );
                }}
                style={{
                  position: "absolute",
                  right: "2%",
                  top: "50%",
                  cursor: event?.Media?.at(mediaID).IsVideo
                    ? "default"
                    : "pointer",
                }}
              />
            </div>
          </div>
          <span
            style={{
              position: "absolute",
              bottom: "0",
              width: "100%",
              textAlign: "center",
            }}
          >
            <input
              readOnly
              type="text"
              contentEditable="false"
              value={
                event.Media ? event.Media[mediaID].MediaURL.split("?v")[0] : ""
              }
              id="urlText"
              style={{ width: "80%" }}
            />
            <ImCopy
              size={30}
              color="black"
              style={{ marginLeft: 10 }}
              onClick={() => {
                if (!event.Media) return;
                copy(event.Media[mediaID].MediaURL.split("?v")[0]);
              }}
            />
          </span>
        </div>
      </ReactModal>

      <ShareWithModal
        isOpen={shareModalState}
        setState={setShareModalState}
        users={allUsers}
        sharedWith={sharedWith}
        eventId={eventID}
        onSave={(newSharedWith) => {
          setSharedWith(newSharedWith);
          handleSharedConfirm(setShareModalState, event, newSharedWith);
        }}
      />

      <div className="card" style={{ margin: "30px" }}>
        <div className="card-header">
          <h5 style={{ display: "inline-block", marginTop: "5px" }}>
            {event.EventDate}
          </h5>
          <span style={{ color: "red", marginLeft: 5 }}>{endDate(event)}</span>
          {pathName.includes("showDeletedMedia") ? null : <MakeZIPComponent event={event} kbSize={2000} blur={true} />}
        </div>
        <div className="card-body" style={{ textAlign: "center" }}>
          <EventOptions
            event={event}
            onEventStateChange={() =>
              downloadData(
                setEvent,
                eventID,
                setSharedWith,
                setAllUsers,
                showDeletedMedia,
                navigate
              )
            }
          />
          {editMode ? (
            <span>
              <input
                value={event.LicensePlate}
                onChange={(e) =>
                  changeLicensePlate(event, setEvent, e.target.value)
                }
                style={{
                  marginBottom: "9px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  fontSize: 37,
                  display: "inline-block",
                }}
              />
              <ImCross
                onClick={() =>
                  handleReset(
                    setEditMode,
                    setEvent,
                    eventID,
                    setSharedWith,
                    setAllUsers
                  )
                }
                style={{
                  fontSize: 40,
                  paddingBottom: "15px",
                  marginLeft: "10px",
                  cursor: "pointer",
                }}
              />
              <ImCheckmark
                onClick={() => handleConfirm(setEditMode, event)}
                style={{
                  fontSize: 40,
                  paddingBottom: "15px",
                  marginLeft: "10px",
                  cursor: "pointer",
                }}
              />
            </span>
          ) : (
            <span>
              <h2
                className="card-title"
                style={{
                  paddingTop: "10px",
                  fontSize: 50,
                  display: "inline-block",
                }}
              >
                {event.LicensePlate === ""
                  ? event.EventDate
                  : event.LicensePlate}
              </h2>
              {event.OwnerID !== localStorage.getItem("userID") ? (
                ""
              ) : (
                <GrEdit
                  onClick={() => setEditMode(true)}
                  style={{
                    fontSize: 40,
                    paddingBottom: "15px",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                />
              )}
            </span>
          )}
          <br />
          {editMode ? (
            <span></span>
          ) : (
            <span>
              <p>
                {"Megosztva: " +
                  getNamesFrom(
                    sharedWith?.filter((user) => user.GroupID !== 0)
                  )}
                {event.OwnerID !== localStorage.getItem("userID") ? (
                  ""
                ) : (
                  <GrEdit
                    onClick={() => {
                      setShareModalState(true);
                    }}
                    style={{
                      fontSize: 20,
                      paddingBottom: "3px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </p>
            </span>
          )}
          <div className="thumbnailList">
            {event.Media
              ? event.Media.map((media, index) => (
                  <Thumbnail
                    key={index}
                    index={index}
                    media={media}
                    setModalState={setModalState}
                    setMediaID={setMediaID}
                    rotateMedia={rotateMedia}
                    onMediaStateChanged={() =>
                      downloadData(
                        setEvent,
                        eventID,
                        setSharedWith,
                        setAllUsers,
                        showDeletedMedia,
                        navigate
                      )
                    }
                    onThumbnailRefresh={
                      (newUrls) =>
                      setEvent((event) => ({
                        ...event,
                        Media: event.Media.map((media, i) => ({
                          ...media,
                          ...(i === mediaID && newUrls),
                        })),
                      }))

                    }
                  />
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventPage;
