import $ from "jquery";
import { useEffect, useState } from "react";
import TimeStampModal from "./TimeStampModal";
import { format as formatDate } from "date-fns";
import SelectMediaModal from "./SelectMediaModal";

function MakeZIPComponent(props) {
  const event = props.event;

  const [limitType, setLimitType] = useState("MB");
  const [mbLimit, setMBLimit] = useState();
  const [resolutionLimit, setResolutionLimit] = useState({
    width: 640,
    height: 480,
  });
  const [blur, setBlur] = useState(false);
  const [timeStamps, setTimeStamps] = useState(null);
  const [openTimestampModal, setOpenTimestampModal] = useState(false);
  const [openSelectModal, setOpenSelectModal] = useState(false);

  useEffect(() => {
    document.body.style.overflow = openTimestampModal ? "hidden" : "unset";
  }, [openTimestampModal, openSelectModal]);

  const filterFiles = (id) =>
    new Promise((resolve, reject) => {
      console.log("File bluring started...");
      const URL = process.env.REACT_APP_SITE;
      $.ajax({
        type: "POST",
        url: URL + `filterMedia`,
        headers: { token: localStorage.getItem("token") },
        contentType: "application/json",
        data: JSON.stringify({
          eventID: id,
        }),
        success: () => resolve(),
        error: function (xhr, err) {
          console.error("Error in file filter!");
          return reject(err);
        },
      });
    });

  const timestampFiles = (id, event) =>
    new Promise((resolve, reject) => {
      console.log("Timestamping files started...");

      const body = event.map((media) => {
        const { fileName, idMedia, date, format } = media;

        const timeStamp = formatDate(
          date,
          format && format !== "" ? format : "yyy-MM-dd HH:mm"
        );

        return { fileName: fileName, idMedia: idMedia, timeStamp: timeStamp };
      });

      const URL = process.env.REACT_APP_SITE;
      $.ajax({
        type: "POST",
        url: URL + `timestampMedia`,
        headers: { token: localStorage.getItem("token") },
        contentType: "application/json",
        data: JSON.stringify({
          eventID: id,
          media: body,
        }),
        success: () => resolve(),
        error: (xhr, err) => {
          console.error("Error in timestamping files!");
          return reject(err);
        },
      });
    });

  const compressFiles = (id, mbSize) =>
    new Promise((resolve, reject) => {
      console.log("File compression started with limit " + mbSize + "MB...");
      const URL = process.env.REACT_APP_SITE;
      $.ajax({
        type: "POST",
        url: URL + `compressMedia`,
        headers: { token: localStorage.getItem("token") },
        contentType: "application/json",
        data: JSON.stringify({
          eventID: id,
          kbSize: mbSize * 1024,
        }),
        success: () => resolve(),
        error: (xhr, err) => {
          console.error("Error in file compression!");
          return reject(err);
        },
      });
    });

  const resizeFiles = (id, resolution) =>
    new Promise((resolve, reject) => {
      console.log(
        "File resizing started with resolution " +
          resolution.width +
          "x" +
          resolution.height +
          "..."
      );
      const URL = process.env.REACT_APP_SITE;
      $.ajax({
        type: "POST",
        url: URL + `resizeMedia`,
        headers: { token: localStorage.getItem("token") },
        contentType: "application/json",
        data: JSON.stringify({
          eventID: id,
          width: resolution.width,
          height: resolution.height,
        }),
        success: () => resolve(),
        error: (xhr, err) => {
          console.error("Error in file resizing!");
          return reject(err);
        },
      });
    });

  const downloadZIP = (id, selected) =>
    new Promise((resolve, reject) => {
      console.log("Making zip...");
      const URL = process.env.REACT_APP_SITE;
      $.ajax({
        url:
          URL +
          `makeZIP?eventID=${id}${
            selected
              ? selected.map((fileName) => "&fileNames[]=" + fileName).join("")
              : ""
          }`,
        headers: { token: localStorage.getItem("token") },
        xhrFields: {
          responseType: "blob",
        },
        success: (data) => resolve(data),
        error: function (xhr, err) {
          console.error("Error in makezip");
          return reject(err);
        },
      });
    });

  const handleDownload = (
    eventId,
    eventName,
    mbSize = -1,
    blur = false,
    filesToTimestamp = null,
    selected = null
  ) => {
    new Promise((resolve, reject) => {
      $("#downloadButton" + eventId).prop("disabled", true);
      $("#downloadButton" + eventId).text("Várakozás...");
      $("#downloadAllButton" + eventId).hide();
      resolve();
    })
      .then(() => {
        if (blur) return filterFiles(eventId);
      })
      .then(() => {
        if (filesToTimestamp) return timestampFiles(eventId, filesToTimestamp);
      })
      .then(() => {
        if (limitType === "MB" && mbSize > 0)
          return compressFiles(eventId, mbSize);
      })
      .then(() => {
        if (limitType === "resolution" && resolutionLimit)
          return resizeFiles(eventId, resolutionLimit);
      })
      .then(() => downloadZIP(eventId, selected))
      .then((data) => {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = `${eventName}.zip`;
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        $("#downloadButton" + eventId).prop("disabled", false);
        $("#downloadButton" + eventId).text("Letöltés");
        $("#downloadAllButton" + eventId).show();
      })
      .catch((err) => {
        $("#downloadButton" + eventId).prop("disabled", false);
        $("#downloadButton" + eventId).text("Letöltés");
        $("#downloadAllButton" + eventId).show();
      });
  };

  return (
    <div className="eventSetting">
      <TimeStampModal
        id={event.EventID}
        setState={setTimeStamps}
        open={openTimestampModal}
        setOpen={setOpenTimestampModal}
      />

      <SelectMediaModal
        id={event.EventID}
        open={openSelectModal}
        handleDownload={(selected) =>
          handleDownload(
            event.EventID,
            event.LicensePlate,
            mbLimit,
            blur,
            timeStamps,
            selected
          )
        }
        setOpen={setOpenSelectModal}
      />

      <label
        style={{
          flexDirection: "row",
          cursor: "pointer",
        }}
        className={"ignoreOpenEvent"}
      >
        Homályosítás:
        <input
          type="checkbox"
          id={`blurRadio` + event.EventID}
          className={"ignoreOpenEvent"}
          checked={blur}
          onChange={() => setBlur(!blur)}
          style={{
            cursor: "pointer",
          }}
        />
      </label>
      <label
        style={{
          flexDirection: "row",
        }}
        className={"ignoreOpenEvent"}
      >
        Kép-méret limit:
        {limitType !== "resolution" ? (
          <input
            type="number"
            placeholder="korlátlan"
            id={`mbLimitNumber` + event.EventID}
            className={"ignoreOpenEvent"}
            style={{
              verticalAlign: "center",
              width: 130,
            }}
            onChange={(e) => setMBLimit(e.target.value)}
            min={0}
            step={1}
          />
        ) : (
          <label
            style={{
              flexDirection: "row",
              width: 130,
            }}
            className={"ignoreOpenEvent"}
          >
            <input
              type="number"
              defaultValue={640}
              placeholder="szélesség"
              id={`widthLimitNumber` + event.EventID}
              className={"ignoreOpenEvent"}
              style={{
                verticalAlign: "center",
                maxWidth: 60,
              }}
              onChange={(e) =>
                setResolutionLimit({
                  ...resolutionLimit,
                  width: e.target.value,
                })
              }
              min={1}
              step={1}
            />
            x
            <input
              type="number"
              defaultValue={480}
              placeholder="magasság"
              id={`heightLimitNumber` + event.EventID}
              className={"ignoreOpenEvent"}
              style={{
                verticalAlign: "center",
                maxWidth: 60,
              }}
              onChange={(e) =>
                setResolutionLimit({
                  ...resolutionLimit,
                  height: e.target.value,
                })
              }
              min={1}
              step={1}
            />
          </label>
        )}
        <select
          style={{ cursor: "pointer" }}
          className={"ignoreOpenEvent"}
          onChange={(e) => {
            setLimitType(e.target.value);
          }}
        >
          <option value={"MB"}>MB</option>
          <option value={"resolution"}>pixel</option>
        </select>
      </label>
      <label
        style={{
          flexDirection: "row",
          verticalAlign: "center",
        }}
        className={"ignoreOpenEvent"}
      >
        Időbélyeg:
        <input
          type="checkbox"
          id={`timeStampRadio` + event.EventID}
          checked={timeStamps !== null}
          className={"ignoreOpenEvent"}
          onChange={(e) =>
            e.target.checked ? setOpenTimestampModal(true) : setTimeStamps(null)
          }
          style={{
            verticalAlign: "center",
            cursor: "pointer",
          }}
        />
      </label>
      <div className="downloadButtons">
        <button
          onClick={() => setOpenSelectModal(true)}
          id={`downloadButton` + event.EventID}
          className="btn btn-primary ignoreOpenEvent"
        >
          Letöltés
        </button>
        <button
          onClick={() =>
            handleDownload(
              event.EventID,
              event.LicensePlate,
              mbLimit,
              blur,
              timeStamps
            )
          }
          id={`downloadAllButton` + event.EventID}
          className="btn btn-primary ignoreOpenEvent"
        >
          Összes letöltése
        </button>
      </div>
    </div>
  );
}

export default MakeZIPComponent;
