import {
  Archive,
  Delete,
  RestoreFromTrash,
  Unarchive,
} from "@mui/icons-material";
import { useState } from "react";
import $ from "jquery";
import { useResolvedPath } from "react-router-dom";

const token = localStorage.getItem("token");
const URL = process.env.REACT_APP_SITE;

const archiveEvent = (eventId, duration, onEventStateChange) => {
  $.ajax({
    url: URL + `archiveEvent?eventId=${eventId}&duration=${duration}`,
    method: "PUT",
    headers: {
      token,
    },
    success: onEventStateChange,
    error: function (error) {
      console.error(error);
    },
  });
};

const deleteEvent = (eventId, onEventStateChange) => {
  $.ajax({
    url: URL + `deleteEvent?eventId=${eventId}`,
    method: "PUT",
    headers: {
      token,
    },
    success: onEventStateChange,
    error: function (error) {
      console.error(error);
    },
  });
};

const restoreEvent = (eventId, onEventStateChange) => {
  $.ajax({
    url: URL + `restoreEvent?eventId=${eventId}`,
    method: "PUT",
    headers: {
      token,
    },
    success: onEventStateChange,
    error: function (error) {
      console.error(error);
    },
  });
};

const restoreAllMedia = (eventId, onEventStateChange, shouldGoBack) => {
  $.ajax({
    url: URL + `restoreMedia?eventId=${eventId}`,
    method: "PUT",
    headers: {
      token,
    },
    success: function () {
      if(shouldGoBack) return 
      return onEventStateChange()
    },
    error: function (error) {
      console.error(error);
    },
  });
};

const DurationSelector = ({
  eventId,
  setShowDurationSelector,
  onEventStateChange,
}) => {
  const durationOptions = [
    { value: "1w", label: "1 hét" },
    { value: "2w", label: "2 hét" },
    { value: "1m", label: "1 hónap" },
    { value: "3m", label: "3 hónap" },
    { value: "6m", label: "6 hónap" },
    { value: "1y", label: "1 év" },
  ];

  return (
    <select
      className="ignoreOpenEvent"
      size={durationOptions.length}
      onChange={(e) => {
        setShowDurationSelector(false);
        archiveEvent(eventId, e.target.value, onEventStateChange);
      }}
    >
      {durationOptions.map(({ value, label }, index) => (
        <option
          key={"durationOption" + index}
          className="ignoreOpenEvent"
          value={value}
        >
          {label}
        </option>
      ))}
    </select>
  );
};

function getEventState({ ArchiveEnds, StoreEnds }) {
  if (ArchiveEnds) return "archive";
  if (StoreEnds) return "deleted";
  return "active";
}

export default function EventOptions(props) {
  const event = props.event;
  const onEventStateChange = props.onEventStateChange;
  const [showDurationSelector, setShowDurationSelector] = useState(false);
  const eventState = getEventState(event);
  const pathName = useResolvedPath().pathname.split("/");

  return (
    <div
      style={{
        display: "flex",
        gap: "5px",
        float: "right",
        alignSelf: "center",
      }}
    >
      {eventState !== "active" || pathName.includes("deleted") || pathName.includes("showDeletedMedia")
       ? null : (
        <div
          title="Archiválás"
          onClick={() => setShowDurationSelector((current) => !current)}
          className="ignoreOpenEvent eventOption"
        >
          <Archive sx={{ color: "dodgerblue" }} className="ignoreOpenEvent" />
        </div>
      )}
      {showDurationSelector ? (
        <DurationSelector
          eventId={event.EventID}
          setShowDurationSelector={setShowDurationSelector}
          onEventStateChange={onEventStateChange}
        />
      ) : null}
      {eventState !== "active" && !pathName.includes("showDeletedMedia") && !pathName.includes("deleted") ? (
        <div
          title="Visszaállítás"
          onClick={() => restoreEvent(event.EventID, onEventStateChange)}
          className="ignoreOpenEvent eventOption"
        >
          <Unarchive sx={{ color: "dodgerblue" }} className="ignoreOpenEvent" />
        </div>
      ) : null}
      {pathName.includes("deleted") ||
      pathName.includes("showDeletedMedia") ||
      eventState === "deleted" ? (
        <div
          title="Visszaállítás"
          onClick={() => {
            console.dir(event)
            if (event.StoreEnds)
              restoreEvent(event.EventID, onEventStateChange);
            if (event.Thumbnails?.some((media) => media.StoreEnds) || event.Media?.some((media) => media.StoreEnds))
              restoreAllMedia(event.EventID, onEventStateChange);
          }}
          className="ignoreOpenEvent eventOption"
        >
          <RestoreFromTrash
            sx={{ color: "dodgerblue" }}
            className="ignoreOpenEvent"
          />
        </div>
      ) : (
        <div
          title="Törlés"
          onClick={() => deleteEvent(event.EventID, onEventStateChange)}
          className="ignoreOpenEvent eventOption"
        >
          <Delete sx={{ color: "dodgerblue" }} className="ignoreOpenEvent" />
        </div>
      )}
    </div>
  );
}
